function toggleCollection(nodes, className) {
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].style.display === 'none') {
      nodes[i].style.display = 'block'
    }

    nodes[i].classList.toggle(className)
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const langSwitcher = document.querySelector('.lang-switcher')

  langSwitcher
    .querySelector('.current-lang')
    .addEventListener('click', function() {
      langSwitcher.classList.toggle('close')
    })

  const menuButton = document.getElementById('menu-toggle')
  const sidebar = document.getElementById('sidebar')
  const metablock = sidebar.querySelector('.meta-block')
  const body = document.querySelector('body')
  const toggles = document.querySelectorAll('.el-show, .el-hide')

  function toggleMenu() {
    metablock.style.transition = 'none'
    metablock.style.opacity = 0
    
    langSwitcher.classList.add('close')
    sidebar.classList.toggle('open')
    body.classList.toggle('sidebar-open')
    toggleCollection(toggles, 'not')

    setTimeout(function() {
      metablock.style.transition = 'opacity 300ms ease'
      metablock.style.opacity = 1
    }, 600)
  }

  document.body.addEventListener('click', function(e) {
    if (sidebar.classList.contains('open') && !e.target.closest('#sidebar')) {
        toggleMenu()
    }
  })

  menuButton.addEventListener('click', toggleMenu)

  for (let el of toggles) {
    el.addEventListener('transitionend', function(e) {
      const list = e.target.classList
      if (list.contains('el-show') && list.contains('not')) {
        el.style.display = 'none'
      } else if (list.contains('el-show')) {
        el.style.display = 'block'
      } 
    })
  }
  
})